
export interface GrupoInterface {
  buscaPlaca: number | string | null;
  usaChecklist: number | string | null;
  alterarValor: string | null;
  formularioDeRecepcao: string | null;
  utilizaMO: string | null;
  oleoFracionado: string | null;
  podeDesconto: string | null;
  assinaturaDoCliente: string | null;
  vendaEstoqueNegativo: string | null;
//   fornecedorBuscaPlaca: string
}

import { ref, onMounted, onUnmounted, defineEmits, watch, Ref } from "vue";
import { defineComponent } from "vue";
import useEmitter from "@/composables/Emmiter";
import Api from "../../services/Api";
import s3 from '../../services/S3Service'
import useAlert from "../../composables/Alert";
import { Modal } from "bootstrap";
import ModalGrupo from "./components/ModalGrupo.vue";
import { useRouter } from "vue-router";

export default defineComponent({

  name: "Grupos",
  
  components: {
    ModalGrupo,
  },

  setup() {
    const emitter = useEmitter();

    const grupoModal: Ref<any> = ref()
    const grupoSelecionado: Ref<any> = ref()
    const router = useRouter()

    const limite = ref(30);
    const pagina: Ref<any> = ref(null);

    const filtro: Ref<any> = ref({ loja: [], automocao: [], comErro: null });

    const loadGrupo: Ref<boolean> = ref(false);

    const arrayGrupo: Ref<any> = ref([]);

    




      // ATUALIZA LISTA DE GRUPO

    emitter.on("atualizaGrupo", (valor: any) => {

      const index = arrayGrupo.value.findIndex(({codGrupo}) => codGrupo === valor.grupo.codGrupo)

      if(index !== -1) arrayGrupo.value[index] = { ...valor.grupo }
      else arrayGrupo.value.unshift({ ...valor.grupo })

    })

    emitter.on("removeGrupo", (valor: any) => {

      const index = arrayGrupo.value.findIndex(({codGrupo}) => codGrupo === valor.grupo.codGrupo)

      if(index !== -1) arrayGrupo.value.splice(index,1)

    })
















    async function buscaGruposPaginado(paginaParam: number, filtrou: boolean = false) {

      try {
        
        loadGrupo.value = true;
  
        const { data } = await Api.get("grupoPaginado", { pagina: paginaParam, limite: limite.value, ...filtro.value });
  
        loadGrupo.value = false;
  
        pagina.value = paginaParam;
  
        if(filtrou) arrayGrupo.value = [];
  
        arrayGrupo.value = [ ...arrayGrupo.value, ...data.grupos ];

      } catch (error) { loadGrupo.value = false; }

    }


    emitter.on("FilterGrupos", (props: any) => {

      filtro.value = props

      buscaGruposPaginado(1, true);

    });

    async function redirectToEdit(idGrupo: number | null){
      await router.push({name:'editarGrupos', query: { idGrupo: idGrupo }})
    };


    ///////////////////// MODAL PRODUTO LOG /////////////////////

    function abreModal(grupo?) {

      grupoSelecionado.value = grupo || {}
      grupoModal.value.show()

    }

    async function fechaModal() {
      
      grupoSelecionado.value = {}
      grupoModal.value.hide()

    }

    ///////////////////// MODAL PRODUTO LOG /////////////////////


    onMounted(() => {

      grupoModal.value = new Modal(document.querySelector(`#ModalGrupo`));

      const observer = new IntersectionObserver(entries => {
        if(entries[0].isIntersecting) buscaGruposPaginado(pagina.value ? pagina.value + 1 : 1);
      }).observe(document.querySelector("#load") as any);

    })




    return {

      arrayGrupo,
      loadGrupo,
      buscaGruposPaginado,
      pagina,
      abreModal,
      fechaModal,
      grupoSelecionado,
      history,
      redirectToEdit,
      router
    }



  }

});
